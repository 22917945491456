import React from 'react';
import { SpacingValue, SystemProp, UnstyledButton } from '@mantine/core';
import { Group, StarIcon, StarOutlineIcon } from '../../../core';
import { Text } from '../../typography';

type ReviewRatingsProp = {
  value: number;
  label?: string;
  width?: number;
  height?: number;
  fz?: SystemProp<SpacingValue>;
  onChange?: (newValue: number) => void;
};

const ReviewRatings = ({ value, label, width, height, onChange, fz }: ReviewRatingsProp): React.ReactElement => {
  return (
    <Group spacing="0.25rem">
      {[1, 2, 3, 4, 5].map((score) => (
        <UnstyledButton key={score} onClick={() => onChange?.(score)} w={width || 14} h={height || 14}>
          {value >= score ? (
            <StarIcon width={width || 14} height={height || 14} />
          ) : (
            <StarOutlineIcon width={width || 14} height={height || 14} />
          )}
        </UnstyledButton>
      ))}
      {label && (
        <Text fz={fz} ml="0.3rem">
          {label}
        </Text>
      )}
    </Group>
  );
};

ReviewRatings.defaultProps = {
  label: undefined,
  width: 14,
  height: 14,
  fz: '12px',
};

export default ReviewRatings;
