import React from 'react';

const StarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.56435 11C9.53911 11 9.51387 10.9944 9.49041 10.9831L6.35706 9.47171L3.22371 10.9831C3.16799 11.01 3.10168 11.0044 3.05132 10.9686C3.00108 10.9328 2.97512 10.8727 2.98405 10.8126L3.48241 7.42576L1.04742 4.97294C1.00408 4.92931 0.988964 4.86574 1.00825 4.80789C1.02754 4.75003 1.07778 4.70734 1.13897 4.69696L4.58067 4.11501L6.20908 1.0876C6.23801 1.03371 6.29503 1 6.35718 1C6.41932 1 6.47635 1.03371 6.50528 1.0876L8.13369 4.11501L11.5754 4.69696C11.6366 4.70734 11.6869 4.75003 11.7061 4.80789C11.7253 4.86574 11.7102 4.92931 11.6669 4.97294L9.23183 7.42576L9.73019 10.8126C9.739 10.8727 9.71316 10.9329 9.66292 10.9686C9.63375 10.9894 9.59911 11 9.56435 11Z"
      fill="#EA98B8"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);

export default StarIcon;
