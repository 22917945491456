import React from 'react';
import cn from '@ui/utils/cn';
import { Button } from '../../buttons/Button/Button';
import { ServerProductProps } from '@ui/nextServer';

type SizeSelectorProps = {
  variants: ServerProductProps['props']['product']['variants'] | undefined;
  ssVariantSkus?: Array<string>;
  value: ServerProductProps['props']['product']['variants'][number] | undefined;
  setVariant: (value: ServerProductProps['props']['product']['variants'][number] | undefined) => void;
  setSSVariantSku?: (value: string | undefined) => void;
  outOfStockModalOpen: (variant?: ServerProductProps['props']['product']['variants'][number]) => void;
  onVariantClick?: (variant: ServerProductProps['props']['product']['variants'][number], sku?: string) => void;
};

const SizeSelector = ({
  variants,
  value,
  setVariant,
  setSSVariantSku,
  outOfStockModalOpen,
  onVariantClick,
}: SizeSelectorProps): React.ReactElement => {
  const isDisabled = (variant?: ServerProductProps['props']['product']['variants'][number]): boolean => {
    if (!variant) return false;
    return Number(variant.quantityAvailable) < 1 || !variant.availableForSale;
  };

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {variants?.map((variant) => (
        <Button
          key={variant.id}
          variant="outline"
          className={cn('size-11 !text-xs p-0', variant.size === 'One Size' && 'px-4 w-auto')}
          muted={variants && isDisabled(variant as any)}
          checked={variant.id === value?.id}
          onClick={() => {
            if (variants && isDisabled(variant as any)) {
              outOfStockModalOpen(variant);
            } else if (variant.id === value?.id) {
              if (setSSVariantSku) {
                setSSVariantSku(undefined);
              }
              setVariant(undefined);
            } else {
              setVariant(variant as any);
              onVariantClick?.(variant as any);
            }
          }}
        >
          {variant.size}
        </Button>
      ))}
    </div>
  );
};

export default SizeSelector;
