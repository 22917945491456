import React from 'react';
import { ProductReview } from '@ui/axios/yotpo/getProductReviews';
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { Dialog, LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import ReviewRatings from '../../visualization/ReviewRatings/ReviewRatings';
import DOMPurify from 'dompurify';
import cn from '@ui/utils/cn';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  review?: ProductReview;
};

const ProductReviewDialog: React.FC<DialogProps> = ({ open, onClose, review }) => {
  const [embla, setEmbla] = React.useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, 500);

  const customFields = review?.custom_fields
    ? Object.values(review?.custom_fields).filter((cf) => ['Size', 'Height'].includes(cf.title))
    : [];

  const hasMultipleImages = (review?.images_data || []).length > 1;

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Content
        classNames={{
          root: 'max-w-5xl p-0 bg-transparent flex items-center',
          close: cn('top-2.5 right-2.5 z-10', hasMultipleImages && 'md:right-11'),
        }}
      >
        {hasMultipleImages && (
          <button onClick={() => embla?.scrollPrev()} className="inline-flex items-center justify-center max-md:hidden">
            <LeftChevronIcon className="[&_path]:stroke-white size-8" />
          </button>
        )}
        <div className="flex-1 bg-white rounded-md border border-black">
          {review && (
            <div className="grid grid-cols-5 md:h-[640px]">
              <div className="col-span-5 md:col-span-3 overflow-hidden max-md:rounded-t md:rounded-l">
                <Carousel
                  loop
                  classNames={{
                    root: 'isolate md:h-full',
                    viewport: 'md:h-full',
                    container: 'md:h-full',
                    controls: 'md:hidden',
                  }}
                  getEmblaApi={setEmbla}
                  nextControlIcon={<RightChevronIcon height={24} width={24} />}
                  previousControlIcon={<LeftChevronIcon height={24} width={24} />}
                  align="center"
                >
                  {review.images_data.map((image) => (
                    <Carousel.Slide className="basis-auto w-full md:h-full md:relative" key={image.id}>
                      <picture>
                        <img
                          alt=""
                          className="w-full h-auto object-contain md:absolute md:h-full"
                          src={image.original_url}
                        />
                      </picture>
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </div>
              <div className="col-span-5 md:col-span-2 min-h-px">
                <div className="p-6 md:py-10 md:h-full md:overflow-y-auto">
                  <div>
                    <div className="flex items-baseline justify-between">
                      <div className="flex items-start space-x-4">
                        <div className="relative">
                          <div className="size-8 bg-black rounded-full flex items-center justify-center text-white uppercase font-bold">
                            {review.user.display_name[0]}
                          </div>
                          {review.verified_buyer && (
                            <div className="rounded-full absolute size-4 bg-white ring-1 ring-white bottom-0 -right-1.5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="size-4 text-[#00b520]"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm3.844-8.791a.75.75 0 0 0-1.188-.918l-3.7 4.79-1.649-1.833a.75.75 0 1 0-1.114 1.004l2.25 2.5a.75.75 0 0 0 1.15-.043l4.25-5.5Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div className="space-y-4">
                          <div>
                            <div className="text-sm font-bold uppercase">{review.user.display_name}</div>
                            {review.verified_buyer && <div className="text-xs">Verified Buyer</div>}
                          </div>
                          <ReviewRatings height={16} width={16} value={review.score} />
                        </div>
                      </div>
                      <div>
                        <time className="text-[12px] tracking-[0.048px]" dateTime={review.created_at}>
                          {new Date(review.created_at).toLocaleDateString()}
                        </time>
                      </div>
                    </div>
                    <div className="mt-6">
                      <div className="text-[14px] font-bold tracking-[0.056px] uppercase">{review.title}</div>
                      <div className="text-[14px] tracking-[0.6px] leading-[1.86] mt-2">{review.content}</div>
                    </div>
                    {customFields.length > 0 && (
                      <div className="mt-4 flex space-x-4">
                        {customFields.map((customField) => (
                          <div key={customField.title} className="flex space-x-2">
                            <span className="text-xs font-bold">{customField.title}:</span>
                            <span
                              className="text-xs"
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(customField.value) }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {hasMultipleImages && (
          <button onClick={() => embla?.scrollNext()} className="inline-flex items-center justify-center max-md:hidden">
            <RightChevronIcon className="[&_path]:stroke-white size-8" />
          </button>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ProductReviewDialog;
