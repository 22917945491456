import React, { useEffect } from 'react';
import { CloseIcon, Modal } from '../../../core';
import ProductMissingSizeForm from '../../forms/OutOfStockForm/OutOfStockForm';
import { ActionIcon } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { Button } from '../../buttons/Button/Button';

type OutOfStockModalProps = {
  opened: boolean;
  onClose: () => void;
  productTitle: string;
  productVariants: { size: string; id: string }[];
  initialVariantId?: string;
};

const OutOfStockModal = ({
  opened,
  onClose,
  productTitle,
  productVariants,
  initialVariantId,
}: OutOfStockModalProps): React.ReactElement => {
  const [successful, setSuccessful] = React.useState(false);
  const { ref, height } = useElementSize();

  const body = (
    <>
      {!successful && (
        <ActionIcon onClick={onClose} className="absolute top-2 right-2 focus:outline-none">
          <CloseIcon width={24} height={24} />
        </ActionIcon>
      )}
      <div ref={ref} className="min-h-[300px]">
        {!successful ? (
          <div>
            <div className="text-[14px] font-bold tracking-[0.04em] text-center">SORRY WE&apos;RE OUT OF STOCK</div>
            <div className="text-[13px] text-black/70 leading-[24px] tracking-[0.03em] mt-3.5">
              Enter your email address below and we will notify you when {productTitle} is back in stock!
            </div>
            <div className="mt-4">
              <ProductMissingSizeForm
                onSuccess={() => setSuccessful(true)}
                sizeOptions={productVariants.map((v) => ({ label: v.size, value: v.id }))}
                initialSize={initialVariantId}
              />
            </div>
          </div>
        ) : (
          <div style={{ height }} className="flex flex-col justify-between space-y-4">
            <div className="text-[14px] font-bold tracking-[0.04em] text-center">
              <div>THANKS!</div>
              <span className="text-[13px] text-black/70 leading-[24px] tracking-[0.03em] mb-auto">
                We will notify you when this product becomes available!
              </span>
            </div>
            <Button variant="primary" className="h-11 text-[13px] w-full" onClick={() => onClose()}>
              Continue Shopping
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <Modal
      body={body}
      opened={opened}
      onClose={onClose}
      transitionProps={{
        onExited: () => {
          setSuccessful(false);
        },
      }}
      withOverlay
      size="397px"
      centered
      classNames={{
        header: 'bg-transparent pb-0',
        body: 'p-8',
      }}
    />
  );
};

export default OutOfStockModal;
