import React from 'react';
import ShareButton from '../../buttons/ShareButton/ShareButton';
import cn from '@ui/utils/cn';
import CarouselIndicators from '../../carousels/CarouselIndicators/CarouselIndicators';
import { ServerProductProps } from '@ui/nextServer';

type ProductImageGridProps = {
  images: ServerProductProps['props']['product']['images'];
  onItemClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: {
      id: string | null | undefined;
      url: any;
      altText: string | null | undefined;
    },
  ) => void;
};

const ProductImageGrid = ({ images, onItemClick }: ProductImageGridProps): React.ReactElement => {
  const imageRowsRef = React.useRef<Array<HTMLElement | null>>([]);
  const [activeImageRow, setActiveImageRow] = React.useState<string>('0');

  React.useEffect(() => {
    const observerHandler: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        const { rowindex } = (entry.target as HTMLElement).dataset;

        if (entry.isIntersecting && rowindex) {
          setActiveImageRow(rowindex);
        }
      });
    };

    const observer = new IntersectionObserver(observerHandler, {
      threshold: 0.6,
    });

    imageRowsRef.current.forEach((node) => {
      if (node) {
        observer.observe(node);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex items-start space-x-4">
      <div className="pt-32 pb-5 space-y-[0.875rem] sticky top-28">
        {images?.length && images.length > 0 && (
          <CarouselIndicators
            activeSlide={+activeImageRow}
            totalSlides={Math.floor(images.length / 2)}
            classNames={{ dot: 'mt-2' }}
            direction="vertical"
            onDotClick={(newImageRow) => {
              imageRowsRef.current[newImageRow]?.scrollIntoView({
                behavior: 'smooth',
              });
              setActiveImageRow(newImageRow.toString());
            }}
          />
        )}
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="grid grid-cols-2 gap-4">
          <div className="pt-10 space-y-4">
            {images
              ?.filter((_, i) => i % 2 === 0)
              .map((image) => (
                <button
                  key={image.id ?? ''}
                  className="w-full block relative pb-[150%] border-none focus:outline-none"
                  onClick={(e) => onItemClick && onItemClick(e, image)}
                >
                  <picture>
                    <img
                      loading="eager"
                      className="size-full object-cover absolute"
                      src={image.url ?? ''}
                      alt={image.altText ?? ''}
                      height={594}
                      width={396}
                    />
                  </picture>
                </button>
              ))}
          </div>
          <div className="space-y-4">
            {images
              ?.filter((_, i) => i % 2 !== 0)
              .map((image, i) => (
                <button
                  key={`${image.id}-product-details-image`}
                  className={cn(
                    'w-full block relative pb-[150%] border-none focus:outline-none',
                    'scroll-mt-[calc(var(--menu-height)+2rem)]',
                    i === 0 && 'scroll-mt-1-[calc(var(--header-height)+4rem)]',
                  )}
                  data-rowindex={i}
                  onClick={(e) => onItemClick && onItemClick(e, image)}
                  ref={(el) => {
                    imageRowsRef.current[i] = el;
                  }}
                >
                  <picture>
                    <img
                      className="size-full object-cover absolute"
                      src={image.url ?? ('' as string)}
                      alt={image.altText ?? ('' as string)}
                      loading={i <= 1 ? 'eager' : 'lazy'}
                      height={594}
                      width={396}
                    />
                  </picture>
                </button>
              ))}

            <ShareButton />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageGrid.defaultProps = {
  onItemClick: undefined,
};

export default ProductImageGrid;
