export const modelData = [
  {
    title: 'model_dominique',
    model_tag: 'model_dominique',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Dom-v2.jpg',
    model_height: '170 cm / 5\'7"',
    model_waist: '61 cm / 22"',
    model_hips: '86 cm / 33"',
    model_bust: '86 cm / 33"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_renee',
    model_tag: 'model_renee',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Renee-v2.jpg',
    model_height: '178 cm / 5\'10"',
    model_waist: '55 cm / 22"',
    model_hips: '83 cm / 33"',
    model_bust: '83 cm / 33"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_belle-lucia',
    model_tag: 'model_belle-lucia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Belle-v2.jpg',
    model_height: '173 cm / 5\'8"',
    model_waist: '60 cm / 24"',
    model_hips: '86 cm / 34"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_shannon',
    model_tag: 'model_shannon',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Shannon-v2.jpg',
    model_height: '172 cm / 5\'7"',
    model_waist: '63 cm / 25"',
    model_hips: '79 cm / 31"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_talia',
    model_tag: 'model_talia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Talia-v2.jpg',
    model_height: '177 cm / 5\'9.5"',
    model_waist: '64 cm / 25"',
    model_hips: '94 cm / 37"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 9.5',
  },
  {
    title: 'model_saskia',
    model_tag: 'model_saskia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Saskia-v2.jpg',
    model_height: '170 cm / 5\'7"',
    model_waist: '64 cm / 25"',
    model_hips: '89 cm / 35"',
    model_bust: '89 cm / 35"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7.5',
  },
  {
    title: 'model_natalie',
    model_tag: 'model_natalie',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Natalie-v2.jpg',
    model_height: '173 cm / 5\'8"',
    model_waist: '58 cm / 23"',
    model_hips: '86 cm / 34"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_cameron',
    model_tag: 'model_cameron',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Cameron-v2.jpg',
    model_height: '170 cm / 5\'5"',
    model_waist: '56 cm / 22"',
    model_hips: '84 cm / 33"',
    model_bust: '76 cm / 29.9"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 6.5',
  },
  {
    title: 'model_casey',
    model_tag: 'model_casey',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Casey-v2.jpg',
    model_height: '168cm / 5\'6"',
    model_waist: '64cm / 25"',
    model_hips: '88cm / 34.5"',
    model_bust: '84cm / 33"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7',
  },
  {
    title: 'model_deni',
    model_tag: 'model_deni',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Deni-v2.jpg',
    model_height: '170 cm / 5\'7"',
    model_waist: '57 cm / 22"',
    model_hips: '91 cm / 35"',
    model_bust: '76 cm / 29.9"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7.5',
  },
  {
    title: 'model_jayde',
    model_tag: 'model_jayde',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Jayde-v2.jpg',
    model_height: '175 cm / 5\'9"',
    model_waist: '61 cm / 24"',
    model_hips: '86 cm / 34"',
    model_bust: '81 cm / 32"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 8.5',
  },
  {
    title: 'model_kaitlynn',
    model_tag: 'model_kaitlynn',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Kaitlyn-v2.jpg',
    model_height: '175 cm / 5\'9"',
    model_waist: '62 cm / 24"',
    model_hips: '86 cm / 33"',
    model_bust: '81 cm / 31.8"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 9',
  },
  {
    title: 'model_madeline',
    model_tag: 'model_madeline',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Madeline-v2.jpg',
    model_height: '170 cm / 5\'6"',
    model_waist: '62 cm / 24"',
    model_hips: '83 cm / 32"',
    model_bust: '84 cm / 33"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 9',
  },
  {
    title: 'model_michaela',
    model_tag: 'model_michaela',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Michaela-v2.jpg',
    model_height: '174 cm / 5\'8"',
    model_waist: '66 cm / 26"',
    model_hips: '89 cm / 35"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_vanessa',
    model_tag: 'model_vanessa',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Vanessa-v2.jpg',
    model_height: '170 cm / 5\'6"',
    model_waist: '57 cm / 22"',
    model_hips: '86 cm / 34"',
    model_bust: '79 cm / 31"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7',
  },
  {
    title: 'model_rochelle',
    model_tag: 'model_rochelle',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Rochelle-v2.jpg',
    model_height: '163 cm / 5\'4"',
    model_waist: '60 cm / 23.5"',
    model_hips: '83 cm / 33"',
    model_bust: '76 cm / 30"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 6',
  },
  {
    title: 'model_katie',
    model_tag: 'model_katie',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Katie.png',
    model_height: '178 cm / 5\'10"',
    model_waist: '86 cm / 34"',
    model_hips: '94 cm / 37"',
    model_bust: '86 cm / 34"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_daia',
    model_tag: 'model_daia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Daia.png',
    model_height: '160 cm / 5\'3"',
    model_waist: '67 cm / 26"',
    model_hips: '92 cm / 36"',
    model_bust: '84 cm / 33"',
    model_dress_size: 'AU 6 / US 4 / S',
    model_shoe_size: 'AU 7',
  },
  {
    title: 'model_lyndl',
    model_tag: 'model_lyndl',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Lyndl.png',
    model_height: '178 cm / 5\'10"',
    model_waist: '69 cm / 27"',
    model_hips: '94 cm / 37"',
    model_bust: '90 cm / 35"',
    model_dress_size: 'AU 10 / US 4 / S',
    model_shoe_size: 'AU 9',
  },
  {
    title: 'model_cathlin',
    model_tag: 'model_cathlin',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Cathlin.jpg',
    model_height: '173 cm / 5\'8"',
    model_waist: '60 cm / 23.6"',
    model_hips: '91 cm / 35.8"',
    model_bust: '86 cm / 33.8"',
    model_dress_size: 'AU 6 / US 4 / S',
    model_shoe_size: 'AU 6',
  },
  {
    title: 'model_pixie',
    model_tag: 'model_pixie',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/A23I0199.jpg',
    model_height: '170 cm / 5\'7"',
    model_waist: '66 cm / 26"',
    model_hips: '87 cm / 34"',
    model_bust: '89 cm / 35"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7.5',
  },
  {
    title: 'model_victoria',
    model_tag: 'model_victoria',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Victoria-v2.jpg',
    model_height: '170 cm / 5\'7"',
    model_waist: '58 cm / 22.8"',
    model_hips: '86 cm / 33.8"',
    model_bust: '83cm / 32.5"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 7 / EU 38',
  },
  {
    title: 'model_amelia',
    model_tag: 'model_amelia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model-Info-Amelia.jpg',
    model_height: '171 cm / 5\'6"',
    model_waist: '60 cm / 23"',
    model_hips: '77 cm / 30"',
    model_bust: '80 cm / 31"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8',
  },
  {
    title: 'model_cecilia',
    model_tag: 'model_cecilia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Model_Info_Cecilia.jpg',
    model_height: '175 cm / 5’9’’',
    model_waist: "58 cm / 23''",
    model_hips: "88cm / 35''",
    model_bust: "81cm / 32''",
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 9 ½',
  },
  {
    title: 'model_elise',
    model_tag: 'model_elise',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/elise.jpg',
    model_height: '169 / 5′6.5″',
    model_waist: '56 / 22”',
    model_hips: '84 / 33"',
    model_bust: '76 / 29.5”',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 6 / US 6 / EU 36',
  },
  {
    title: 'model_denisa',
    model_tag: 'model_denisa',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Denisa.jpg',
    model_height: '182 cm / 5\'11"',
    model_waist: '62 cm / 24.4"',
    model_hips: '89 cm / 35"',
    model_bust: '82 cm / 32.3"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 9',
  },
  {
    title: 'model_madi',
    model_tag: 'model_madi',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_madi.jpg',
    model_height: '174 cm / 5’7”',
    model_waist: '64 cm / 25”',
    model_hips: '95 cm / 37”',
    model_bust: '89 cm / 35”',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 9',
  },
  {
    title: 'model_evie',
    model_tag: 'model_evie',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_evie.jpg',
    model_height: '178.5cm / 5\'10"',
    model_waist: '66cm / 25.9"',
    model_hips: '91cm / 35.8"',
    model_bust: '84cm / 33"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 10',
  },
  {
    title: 'model_paris',
    model_tag: 'model_paris',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_paris.jpg',
    model_height: '176cm / 5\'9"',
    model_waist: '64cm / 25”',
    model_hips: '90cm / 35.4”',
    model_bust: '80cm / 31.4”',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 8 / EU 39',
  },
  {
    title: 'model_tori',
    model_tag: 'model_tori',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_tori.jpg',
    model_height: '175cm / 5\'9"',
    model_waist: '61cm / 24"',
    model_hips: '91cm / 34"',
    model_bust: '84cm / 32"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 9 / EU 40',
  },
  {
    title: 'model_adison',
    model_tag: 'model_adison',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_adison.jpg',
    model_height: '165cm / 5\'5"',
    model_waist: '58.5cm / 23"',
    model_hips: '89.5cm / 35"',
    model_bust: '80cm / 31.5"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8 / EU 39',
  },
  {
    title: 'model_jemma',
    model_tag: 'model_jemma',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_jemma.jpg',
    model_height: '172 cm / 5\'7"',
    model_waist: '61 cm / 24"',
    model_hips: '89 cm / 35"',
    model_bust: '76 cm / 30"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 8 / EU 39',
  },
  {
    title: 'model_kiana',
    model_tag: 'model_kiana',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_kiana.jpg',
    model_height: '170cm / 5\'7"',
    model_waist: '58cm / 23"',
    model_hips: '84cm / 33"',
    model_bust: '76cm / 30"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8 / EU 39',
  },
  {
    title: 'model_kinsey',
    model_tag: 'model_kinsey',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_kinsey.jpg',
    model_height: '170cm / 5\'7"',
    model_waist: '58cm / 23"',
    model_hips: '86cm / 34"',
    model_bust: '81cm / 32"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 6 / EU 37',
  },
  {
    title: 'model_olivia',
    model_tag: 'model_olivia',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_olivia.jpg',
    model_height: '170cm / 5\'7"',
    model_waist: '60cm / 23.5"',
    model_hips: '89cm / 35"',
    model_bust: '79cm / 31"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8 / EU 39',
  },
  {
    title: 'model_elly',
    model_tag: 'model_elly',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_elly.jpg',
    model_height: '173cm / 5\'8"',
    model_waist: '62cm / 24"',
    model_hips: '88cm / 35"',
    model_bust: '82cm / 31.5"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 8 / EU 38.5',
  },
  {
    title: 'model_amanda',
    model_tag: 'model_amanda',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_amanda.jpg',
    model_height: '180cm / 5\'11"',
    model_waist: '70cm / 27.5"',
    model_hips: '98cm / 38.5"',
    model_bust: '85cm / 33.5"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 9 / EU 40',
  },
  {
    title: 'model_narah',
    model_tag: 'model_narah',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_narah.jpg',
    model_height: '175cm / 5\'9"',
    model_waist: '67cm / 26.5"',
    model_hips: '98cm / 38.5"',
    model_bust: '83cm / 32.5"',
    model_dress_size: 'AU 8 / US 4 / S',
    model_shoe_size: 'AU 9 / EU 40',
  },
  {
    title: 'model_allie',
    model_tag: 'model_allie',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_allie.jpg',
    model_height: '172cm / 5\'8"',
    model_waist: '61cm / 24"',
    model_hips: '86cm / 34"',
    model_bust: '81cm / 32"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7 / EU 38',
  },
  {
    title: 'model_nathalya',
    model_tag: 'model_nathalya',
    model_image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/model_nathalya.jpg',
    model_height: '165cm / 5\'5"',
    model_waist: '59cm / 23"',
    model_hips: '81cm / 32"',
    model_bust: '77 cm / 30"',
    model_dress_size: 'AU 6 / US 2 / XS',
    model_shoe_size: 'AU 7 / EU 38',
  },
];
