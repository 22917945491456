import React from 'react';
import createProductReview from '@ui/axios/yotpo/createProductReview';
import { FormHelperText, FormInput, FormLabel, FormRadio, FormTextarea } from '@ui/components/core';
import { Button } from '@ui/components/shared';
import { ReviewRatings } from '@ui/components/shared';
import { Radio } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import env from '@ui/env';
import axios from 'axios';

type ProductReviewFormProps = {
  className?: string;
  onSuccess?: () => void;
  product: {
    id: string;
    handle: string;
    title: string;
    url: string;
  };
};

export default function ProductReviewForm({ className, onSuccess, product }: ProductReviewFormProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  const form = useForm({
    initialValues: {
      reviewerName: '',
      reviewerEmail: '',
      reviewContent: '',
      reviewTitle: '',
      reviewScore: '',
      customFieldSize: '',
      customFieldHeight: '',
      customFieldFit: '',
    },
    validate: {
      reviewerName: (value) => (value.trim().length ? null : 'Name is required.'),
      reviewerEmail: (value) => (value.trim().length ? null : 'Email is required.'),
      reviewContent: (value) => (value.trim().length ? null : 'Content is required.'),
      reviewTitle: (value) => (value.trim().length ? null : 'Title is required.'),
      reviewScore: (value) => (value.trim().length ? null : 'Score is required.'),
      customFieldSize: (value) => (value.trim().length ? null : 'Size is required.'),
      customFieldHeight: (value) => (value.trim().length ? null : 'Height is required.'),
      customFieldFit: (value) => (value.trim().length ? null : 'Fit is required.'),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  return (
    <form
      noValidate
      className={className}
      onSubmit={form.onSubmit(async (values, event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
          if (!env.PRODUCT_REVIEWS_CREATE_FEATURE) {
            throw new Error('Review creation is disabled');
          }

          const productId = env.PRODUCT_REVIEWS_REROUTE_TO_AU
            ? (await axios.get(`https://hellomolly.com.au/products/${product.handle}.json`)).data.product.id
            : product.id;

          await createProductReview({
            productId: productId,
            productTitle: product.title,
            productUrl: product.url,
            reviewerName: values.reviewerName,
            reviewerEmail: values.reviewerEmail,
            reviewContent: values.reviewContent,
            reviewTitle: values.reviewTitle,
            reviewScore: Number(values.reviewScore),
            customFieldSize: values.customFieldSize,
            customFieldHeight: values.customFieldHeight,
            customFieldFit: values.customFieldFit,
          });

          onSuccess?.();
        } catch (err) {
          notifications.show({
            autoClose: 3000,
            withCloseButton: true,
            variant: 'danger',
            message: 'An error occurred when creating your review.',
          });
        }

        setIsLoading(false);
      })}
    >
      <div className="space-y-6">
        <div>
          <FormLabel required>Score</FormLabel>

          <div className="mt-2">
            <ReviewRatings
              value={Number(form.values.reviewScore)}
              onChange={(newScore) => form.setFieldValue('reviewScore', newScore.toString())}
              width={20}
              height={20}
            />
          </div>

          {form.errors.reviewScore && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewScore}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewTitle" required>
            Title
          </FormLabel>

          <FormInput
            {...form.getInputProps('reviewTitle', { withError: false })}
            invalid={Boolean(form.errors.reviewTitle)}
            autoComplete="off"
            id="reviewTitle"
            name="reviewTitle"
            type="text"
            className="md:max-w-[296px]"
            required
          />

          {form.errors.reviewTitle && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewTitle}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewContent" required>
            Review
          </FormLabel>

          <FormTextarea
            {...form.getInputProps('reviewContent', { withError: false })}
            invalid={Boolean(form.errors.reviewContent)}
            autoComplete="off"
            id="reviewContent"
            name="reviewContent"
            required
            rows={4}
          />

          {form.errors.reviewContent && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewContent}
            </FormHelperText>
          )}
        </div>

        <div className="md:p-8 md:border md:border-black md:rounded-sm">
          <FormLabel required>How does it fit?</FormLabel>

          <Radio.Group
            value={form.values.customFieldFit}
            onChange={(value) => form.setFieldValue('customFieldFit', value)}
            name="reviewFit"
            size="xs"
          >
            <div className="grid gap-4 md:grid-cols-3 mt-4">
              <FormRadio
                value="1"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px]">Smaller than expected</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#DE4D58]"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                    </div>
                  </div>
                }
              />
              <FormRadio
                value="2"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px]">True to size</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#46B67A]"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                    </div>
                  </div>
                }
              />
              <FormRadio
                value="3"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px]">Larger than expected</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#DE4D58]"></div>
                    </div>
                  </div>
                }
              />
            </div>
          </Radio.Group>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
          <div>
            <FormLabel htmlFor="customFieldSize" required>
              What size did you purchase?
            </FormLabel>

            <FormInput
              {...form.getInputProps('customFieldSize', { withError: false })}
              invalid={Boolean(form.errors.customFieldSize)}
              autoComplete="off"
              id="customFieldSize"
              name="customFieldSize"
              type="text"
              required
            />

            {form.errors.customFieldSize && (
              <FormHelperText className="mt-1" invalid>
                {form.errors.customFieldSize}
              </FormHelperText>
            )}
          </div>

          <div>
            <FormLabel htmlFor="customFieldHeight" required>
              How tall are you?
            </FormLabel>

            <FormInput
              {...form.getInputProps('customFieldHeight', { withError: false })}
              invalid={Boolean(form.errors.customFieldHeight)}
              autoComplete="off"
              id="customFieldHeight"
              name="customFieldHeight"
              type="text"
              required
            />

            {form.errors.customFieldHeight && (
              <FormHelperText className="mt-1" invalid>
                {form.errors.customFieldHeight}
              </FormHelperText>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
          <div>
            <FormLabel htmlFor="reviewerName" required>
              Your name (will be visible to the public)
            </FormLabel>

            <FormInput
              {...form.getInputProps('reviewerName', { withError: false })}
              invalid={Boolean(form.errors.reviewerName)}
              autoComplete="name"
              id="reviewerName"
              name="reviewerName"
              type="text"
              required
            />

            {form.errors.reviewerName && (
              <FormHelperText className="mt-1" invalid>
                {form.errors.reviewerName}
              </FormHelperText>
            )}
          </div>

          <div>
            <FormLabel htmlFor="reviewerEmail" required>
              Your email (for validation purposes only)
            </FormLabel>

            <FormInput
              {...form.getInputProps('reviewerEmail', { withError: false })}
              invalid={Boolean(form.errors.reviewerEmail)}
              autoComplete="email"
              id="reviewerEmail"
              name="reviewerEmail"
              type="email"
              required
            />

            {form.errors.reviewerEmail && (
              <FormHelperText className="mt-1" invalid>
                {form.errors.reviewerEmail}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
      <div className="flex mt-8 justify-start">
        <Button className="h-10 text-[14px] md:text-[14px] font-normal" disabled={isLoading} type="submit">
          Post
        </Button>
      </div>
    </form>
  );
}
