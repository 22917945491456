import React from 'react';

const StarOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5645 17C14.5246 17 14.4846 16.991 14.4474 16.9729L9.48472 14.5547L4.52199 16.9729C4.43374 17.0161 4.32872 17.0071 4.24896 16.9498C4.16938 16.8925 4.12828 16.7964 4.14242 16.7001L4.93173 11.2812L1.0751 7.3567C1.00647 7.2869 0.98252 7.18519 1.01307 7.09262C1.04361 7.00005 1.12319 6.93174 1.22011 6.91513L6.67121 5.98402L9.25034 1.14016C9.29616 1.05394 9.38648 1 9.48491 1C9.58334 1 9.67366 1.05394 9.71948 1.14016L12.2986 5.98402L17.7497 6.91513C17.8466 6.93174 17.9264 7.00005 17.9567 7.09262C17.9871 7.18519 17.9632 7.2869 17.8947 7.3567L14.0379 11.2812L14.8272 16.7001C14.8412 16.7964 14.8002 16.8927 14.7207 16.9498C14.6745 16.983 14.6196 17 14.5645 17Z"
      fill="white"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);

export default StarOutlineIcon;
