import { Select as SelectPrimitive, SelectProps as SelectPrimitiveProps } from '@mantine/core';
import React from 'react';
import DownChevronIcon from '../Icons/Common/DownChevronIcon';
import cn from '@ui/utils/cn';

export type SelectProps = Omit<SelectPrimitiveProps, 'onDropdownClose'> & {
  invalid?: boolean;
  onClose?: () => void;
  classNames?: {
    root?: string;
    input?: string;
    dropdown?: string;
    item?: string;
  };
};

const Select = React.forwardRef<HTMLInputElement, SelectProps>(({ classNames, onClose, invalid, ...props }, ref) => {
  return (
    <SelectPrimitive
      {...props}
      ref={ref}
      error={invalid}
      onDropdownClose={onClose}
      rightSection={<DownChevronIcon width={16} height={16} />}
      classNames={{
        root: cn('group', classNames?.root),
        rightSection: '[.group_[aria-expanded=true]_&]:rotate-180 pointer-events-none transition',
        input: cn(
          'block w-full px-3 py-0 h-11 rounded-[4px] transition text-base tracking-[0.005em]',
          'bg-white border border-[#757575] placeholder:text-black',
          'data-[invalid]:border-[#F44336]',
          'data-[disabled]:border-[#9E9E9E] data-[disabled]:text-[#9E9E9E]',
          'hover:border-[#424242]',
          'focus:border-[#212121] focus:outline-none',
          classNames?.input,
        ),
        dropdown: cn('px-0 py-1 rounded-[4px] shadow-none border border-[#757575] bg-white', classNames?.dropdown),
        itemsWrapper: 'p-0',
        item: cn(
          'px-3 py-2.5 flex items-center text-[13px] rounded-none !bg-white !text-black group',
          'data-[hovered]:underline data-[hovered]:underline-offset-[6px]',
          'data-[selected]:underline data-[selected]:underline-offset-[6px]',
          classNames?.item,
        ),
      }}
    />
  );
});

Select.displayName = 'Select';

export default Select;
