import getProductReviews from '@ui/axios/yotpo/getProductReviews';
import { ReviewRatings } from '@ui/components/shared';
import env from '@ui/env';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ServerProductProps } from '@ui/nextServer';

type ReviewSummaryProps = {
  className?: string;
  product: ServerProductProps['props']['product'];
};

export default function ReviewSummary({ className, product }: ReviewSummaryProps) {
  const { data } = useQuery({
    queryKey: ['productDetailsPageReviews', product.handle],
    queryFn: async () => {
      const productId = env.PRODUCT_REVIEWS_REROUTE_TO_AU
        ? (await axios.get(`https://content.hellomolly.com.au/products/${product.handle}.json`)).data.product.id
        : product.id;

      return getProductReviews({ productId }).catch(() => null);
    },
  });

  const bottomline = data?.data.response.bottomline || {
    average_score: 0,
    total_review: 0,
  };

  if (!env.PRODUCT_REVIEWS_FEATURE) {
    return null;
  }

  return (
    <a
      href="#reviews"
      className={className}
      onClick={() => {
        if (bottomline.total_review > 0) return;
        const selector = '[data-review-form-open]';
        const button = document.querySelector<HTMLElement>(selector);
        button?.click();
      }}
    >
      <ReviewRatings
        value={bottomline.average_score}
        label={
          bottomline.total_review <= 0
            ? 'Write a review'
            : bottomline.total_review != 1
              ? `${bottomline.total_review} reviews`
              : '1 review'
        }
      />
    </a>
  );
}
