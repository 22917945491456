import axios from 'axios';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Args<T> = {
  cachedData: T;
  actualData: T;
};

export default function useRevalidate<T>({ cachedData, actualData }: Args<T>) {
  const router = useRouter();

  useEffect(() => {
    if (isEqual(cachedData, actualData)) return;
    revalidate(router.asPath);
  }, [cachedData, actualData, router.asPath]);
}

async function revalidate(validationPath: string) {
  try {
    const token = (await axios.head('/')).headers['x-csrf-token'] as string;
    const headers = { 'X-CSRF-Token': token };
    await axios.post('/api/revalidate', { validationPath }, { headers });
  } catch {
    //
  }
}
