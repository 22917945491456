import React from 'react';
import { Carousel, LeftChevronIcon, RightChevronIcon, ZoomIcon } from '@ui/components/core';
import { Embla } from '@mantine/carousel';
import { flushSync } from 'react-dom';
import { ActionIcon } from '@mantine/core';
import CarouselIndicators from '../CarouselIndicators/CarouselIndicators';
import { ServerProductProps } from '@ui/nextServer';

type ProductImageCarouselProps = {
  images: ServerProductProps['props']['product']['images'];
  onItemClick?: (image: any) => void;
};

const TWEEN_FACTOR = 1;

const ProductImageCarousel = ({ images, onItemClick }: ProductImageCarouselProps): React.ReactElement => {
  const [embla, setEmbla] = React.useState<Embla>();
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [tweenValues, setTweenValues] = React.useState<Array<number> | undefined>(
    images?.map((_, i) => {
      if (i === 0) {
        return 1;
      } else {
        return 0.9;
      }
    }),
  );

  const onScroll = React.useCallback((instance: Embla) => {
    const internalEngine = instance.internalEngine();
    const scrollProgress = instance.scrollProgress();

    const newTweenValues = instance.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (internalEngine.options.loop) {
        internalEngine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target().get();

          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }

      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);

      return Math.min(Math.max(tweenValue, 0.9), 1);
    });

    setTweenValues(newTweenValues);
  }, []);

  React.useEffect(() => {
    if (!embla) return;

    onScroll(embla);

    const handleScroll = () => flushSync(() => onScroll(embla));
    const handleReinit = () => onScroll(embla);

    embla.on('scroll', handleScroll);
    embla.on('reInit', handleReinit);

    return () => {
      embla.off('scroll', handleScroll);
      embla.off('reInit', handleReinit);
    };
  }, [embla, onScroll]);

  return (
    <div>
      <Carousel
        getEmblaApi={setEmbla}
        withControls={false}
        onSlideChange={setActiveSlide}
        nextControlIcon={<RightChevronIcon height={24} width={24} />}
        previousControlIcon={<LeftChevronIcon height={24} width={24} />}
        align="center"
        classNames={{
          root: 'w-full',
          indicators: '-bottom-6 gap-2.5',
          slide: 'relative basis-auto w-[80%]',
        }}
      >
        {images?.map((image, i) => (
          <Carousel.Slide style={{ transform: `scale(${tweenValues ? tweenValues[i] : ''})` }} key={image.id}>
            <div className="relative pb-[150%] isolate" onClick={() => onItemClick?.(image)}>
              <ActionIcon size={44} className="absolute right-0 top-0 z-10 active:bg-transparent">
                <ZoomIcon width={20} height={20} />
              </ActionIcon>
              <picture>
                <img
                  className="rounded-[4px] absolute w-full h-full object-cover"
                  src={image.url ?? ('' as string)}
                  alt={image.altText ?? ('' as string)}
                  width={320}
                  height={480}
                  loading={i > 1 ? 'lazy' : 'eager'}
                />
              </picture>
            </div>
          </Carousel.Slide>
        ))}
      </Carousel>
      <CarouselIndicators
        className="absolute -bottom-6 md:opacity-0"
        activeSlide={activeSlide}
        totalSlides={images?.length || 0}
      />
    </div>
  );
};

ProductImageCarousel.defaultProps = {
  onItemClick: undefined,
};

export default ProductImageCarousel;
