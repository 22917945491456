import React from 'react';
import { Table } from '@mantine/core';
import { Box } from '../../../core';
import { Button } from '@ui/components/shared';

type SizesTableProps = {
  productType: string;
};

const SizesTable = ({ productType }: SizesTableProps) => {
  const clothingSizes = [
    {
      size: 'XS',
      au: '6',
      us: '2',
      uk: '6',
      eu: '34',
    },
    {
      size: 'S',
      au: '8',
      us: '4',
      uk: '8',
      eu: '36',
    },
    {
      size: 'M',
      au: '10',
      us: '6',
      uk: '10',
      eu: '38',
    },
    {
      size: 'L',
      au: '12',
      us: '8',
      uk: '12',
      eu: '40',
    },
    {
      size: 'XL',
      au: '14',
      us: '10',
      uk: '14',
      eu: '42',
    },
  ];

  const shoeSizes = [
    {
      au: '4',
      us: '4',
      uk: '2',
      eu: '35',
    },
    {
      au: '5',
      us: '5',
      uk: '3',
      eu: '36',
    },
    {
      au: '6',
      us: '6',
      uk: '4',
      eu: '37',
    },
    {
      au: '7',
      us: '7',
      uk: '5',
      eu: '38',
    },
    {
      au: '8',
      us: '8',
      uk: '6',
      eu: '39',
    },
    {
      au: '9',
      us: '9',
      uk: '7',
      eu: '40',
    },
    {
      au: '10',
      us: '10',
      uk: '8',
      eu: '41',
    },
    {
      au: '11',
      us: '11',
      uk: '9',
      eu: '42',
    },
  ];

  const clothingSizeRows = clothingSizes.map((clothingSize) => (
    <tr key={`${clothingSize.size}-table-row`}>
      <td>{clothingSize.size}</td>
      <td>{clothingSize.au}</td>
      <td>{clothingSize.us}</td>
      <td>{clothingSize.uk}</td>
      <td>{clothingSize.eu}</td>
    </tr>
  ));

  const shoeSizeRows = shoeSizes.map((shoeSize) => (
    <tr key={`${shoeSize.au}-table-row`}>
      <td>{shoeSize.au}</td>
      <td>{shoeSize.us}</td>
      <td>{shoeSize.uk}</td>
      <td>{shoeSize.eu}</td>
    </tr>
  ));

  return (
    <Box
      sx={{
        overflow: 'hidden',
        border: '1px solid',
        borderRadius: '0.13rem',
      }}
    >
      {productType === 'Clothes' && (
        <Table
          withColumnBorders
          sx={(theme) => ({
            // borderColor: 'black',
            // borderRadius: '2px',
            '& > thead > tr > th': {
              backgroundColor: theme.colors.brand[8],
              border: '1px dashed #000 !important',
              borderTop: 'none !important',
              color: theme.black,
              fontSize: '13px',
              fontWeight: 700,
              paddingBottom: '0.625rem',
              paddingTop: '0.625rem',
              textAlign: 'center',
              textTransform: 'uppercase',
            },
            '& > tbody > tr > td': {
              border: '1px dashed #000 !important',
              color: theme.black,
              fontSize: '13px',
              fontWeight: 700,
              paddingBottom: '0.625rem',
              paddingTop: '0.625rem',
              textAlign: 'center',
              textTransform: 'uppercase',
            },
            '& > thead > tr > th:first-of-type': {
              borderLeft: 'none !important',
            },
            '& > thead > tr > th:last-of-type': {
              borderRight: 'none !important',
            },
            '& > tbody > tr > td:first-of-type': {
              borderLeft: 'none !important',
            },
            '& > tbody > tr > td:last-of-type': {
              borderRight: 'none !important',
            },
            '& > tbody > tr:last-of-type > td': {
              borderBottom: 'none !important',
            },
          })}
        >
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Size</th>
              <th style={{ width: '20%' }}>AU/NZ</th>
              <th style={{ width: '20%' }}>US</th>
              <th style={{ width: '20%' }}>UK</th>
              <th style={{ width: '20%' }}>EUR</th>
            </tr>
          </thead>
          <tbody>
            {clothingSizeRows}
            <tr>
              <td colSpan={5}>
                <Button
                  variant="unstyled"
                  className="my-2 md:text-[13px]"
                  onClick={() => window.open('/pages/size-guide/', '_blank')}
                >
                  Full Size Guide
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      {productType === 'Shoes' && (
        <Table
          withColumnBorders
          sx={(theme) => ({
            // borderColor: 'black',
            // borderRadius: '2px',
            '& > thead > tr > th': {
              backgroundColor: theme.colors.brand[8],
              border: '1px dashed #000 !important',
              borderTop: 'none !important',
              color: theme.black,
              fontSize: '13px',
              fontWeight: 700,
              paddingBottom: '0.625rem',
              paddingTop: '0.625rem',
              textAlign: 'center',
              textTransform: 'uppercase',
            },
            '& > tbody > tr > td': {
              border: '1px dashed #000 !important',
              color: theme.black,
              fontSize: '13px',
              fontWeight: 700,
              paddingBottom: '0.625rem',
              paddingTop: '0.625rem',
              textAlign: 'center',
              textTransform: 'uppercase',
            },
            '& > thead > tr > th:first-of-type': {
              borderLeft: 'none !important',
            },
            '& > thead > tr > th:last-of-type': {
              borderRight: 'none !important',
            },
            '& > tbody > tr > td:first-of-type': {
              borderLeft: 'none !important',
            },
            '& > tbody > tr > td:last-of-type': {
              borderRight: 'none !important',
            },
            '& > tbody > tr:last-of-type > td': {
              borderBottom: 'none !important',
            },
          })}
        >
          <thead>
            <tr>
              <th style={{ width: '20%' }}>AU/NZ</th>
              <th style={{ width: '20%' }}>US</th>
              <th style={{ width: '20%' }}>UK</th>
              <th style={{ width: '20%' }}>EUR</th>
            </tr>
          </thead>
          <tbody>
            {shoeSizeRows}
            <tr>
              <td colSpan={5}>
                <Button
                  variant="unstyled"
                  className="my-2 md:text-[13px]"
                  onClick={() => window.open('/pages/size-guide/', '_blank')}
                >
                  Full Size Guide
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </Box>
  );
};
export default SizesTable;
