import React from 'react';
import { ShareIcon, ThumbDownIcon, ThumbUpIcon } from '@ui/components/core';
import { ReviewRatings } from '@ui/components/shared';
import { UnstyledButton } from '@mantine/core';
import Image from 'next/image';
import voteProductReview from '@ui/axios/yotpo/voteProductReview';
import env from '@ui/env';
import { ProductReview } from '@ui/axios/yotpo/getProductReviews';
import DOMPurify from 'dompurify';
import cn from '@ui/utils/cn';

type Vote = 'up' | 'down' | null;

type Props = {
  review: ProductReview;
  onImageClick?: () => void;
};

export default function ProductReviewCard({ review, onImageClick }: Props) {
  const customFields = review.custom_fields
    ? Object.values(review.custom_fields).filter((cf) => ['Size', 'Height'].includes(cf.title))
    : [];

  const [vote, setVote] = React.useState<Vote>(null);
  const [upvotes, setUpvotes] = React.useState(review.votes_up);
  const [downvotes, setDownvotes] = React.useState(review.votes_down);
  const [isVoting, setIsVoting] = React.useState(false);

  async function handleVote(previousVote: Vote, newVote: Vote) {
    if (previousVote === newVote) return;

    setIsVoting(true);

    try {
      if (previousVote) {
        await voteProductReview({
          reviewId: review.id.toString(),
          vote: previousVote,
          undo: true,
        });
      }

      if (newVote && previousVote !== newVote) {
        await voteProductReview({
          reviewId: review.id.toString(),
          vote: newVote,
        });
      }

      if (previousVote === 'up') {
        setUpvotes((previousUpvote) => previousUpvote - 1);
      } else if (previousVote === 'down') {
        setDownvotes((previousDownvote) => previousDownvote - 1);
      }

      if (newVote === 'up') {
        setUpvotes((previousUpvote) => previousUpvote + 1);
      } else if (newVote === 'down') {
        setDownvotes((previousDownvote) => previousDownvote + 1);
      }

      setVote(newVote);
    } catch {
      //
    }

    setIsVoting(false);
  }

  const UpvoteButton = ({ className }: { className?: string }) => (
    <UnstyledButton
      onClick={() => handleVote(vote, 'up')}
      className={cn('flex items-center space-x-1 disabled:opacity-20 transition-opacity', className)}
      disabled={isVoting}
    >
      <ThumbUpIcon width={18} height={17} />
      <span className={cn('text-[15px]', vote === 'up' && 'font-black')}>{upvotes}</span>
    </UnstyledButton>
  );

  const DownvoteButton = ({ className }: { className?: string }) => (
    <UnstyledButton
      onClick={() => handleVote(vote, 'down')}
      className={cn('flex items-center space-x-1 disabled:opacity-20 transition-opacity', className)}
      disabled={isVoting}
    >
      <ThumbDownIcon width={18} height={17} />
      <span className={cn('text-[15px]', vote === 'down' && 'font-black')}>{downvotes}</span>
    </UnstyledButton>
  );

  const ShareButton = ({ className }: { className?: string }) => (
    <UnstyledButton className={cn('flex items-center', className)}>
      <ShareIcon width={15} height={20} />
    </UnstyledButton>
  );

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="flex items-baseline justify-between">
          <div className="space-y-3 md:space-y-4">
            <div className="flex items-end space-x-1">
              <div className="text-xs leading-[16px] font-bold uppercase">{review.user.display_name}</div>
              {review.verified_buyer && <div className="text-xs leading-[16px] font-bold">Verified Buyer</div>}
            </div>
            <ReviewRatings height={16} width={16} value={review.score} />
          </div>
          <div>
            <time className="text-[12px] tracking-[0.048px]" dateTime={review.created_at}>
              {new Date(review.created_at).toLocaleDateString()}
            </time>
          </div>
        </div>
        <div className="mt-5">
          <div className="text-[14px] font-bold tracking-[0.056px] uppercase">{review.title}</div>
          <div className="text-[14px] tracking-[0.6px] leading-[1.86] mt-2">{review.content}</div>
        </div>
        {customFields.length > 0 && (
          <div className="mt-4 flex space-x-4">
            {customFields.map((customField) => (
              <div key={customField.title} className="flex space-x-2">
                <span className="text-xs font-bold">{customField.title}:</span>
                <span className="text-xs" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(customField.value) }} />
              </div>
            ))}
          </div>
        )}
        {review.images_data?.length && (
          <div className="grid grid-cols-3 md:grid-cols-4 gap-2 mt-4">
            {review.images_data.map((image) => (
              <div key={image.id} className="relative pb-[100%]">
                <Image
                  className="w-full h-full absolute cursor-pointer"
                  width={150}
                  height={150}
                  src={image.thumb_url}
                  alt=""
                  onClick={() => onImageClick?.()}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {env.PRODUCT_REVIEWS_VOTE_FEATURE && (
        <div className="mt-4">
          <div className="hidden md:flex items-center justify-between">
            <div className="text-[13px] font-normal">Was this review helpful?</div>
            <div className="flex items-center">
              <UpvoteButton />
              <DownvoteButton className="ml-4" />
              <ShareButton className="ml-8" />
            </div>
          </div>
          <div className="flex md:hidden items-end justify-between">
            <ShareButton />
            <div className="flex">
              <UpvoteButton />
              <DownvoteButton className="ml-4" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
