import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Accordion, AfterpayIcon, Badge, Box, KlarnaIcon, Prose, Stack } from '../../../core';
import {
  CartUpsellCarousel,
  FinalSaleModal,
  ModelCard,
  OutOfStockModal,
  ProductBrandFromTags,
  SizeSelector,
  SizesTable,
  WishlistButton,
  Button,
  WearNowPayLaterDialog,
  Money,
} from '../../../shared';
import { AppContext } from '@ui/context/context';
import { useRouter } from 'next/router';
import env from '@ui/env';
import OutOfStock from '../../../shared/labels/OutOfStock/OutOfStock';
import { modelData } from '@ui/utils/modelData';
import StickyAddToCartPanel from './StickyAddToCartPanel';
import AddToCartButton from './AddToCartButton';
import cn from '@ui/utils/cn';
import ReviewSummary from './ReviewSummary';
import DOMPurify from 'dompurify';
import Link from 'next/link';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';
import { ServerProductProps } from '@ui/nextServer';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { useQuery } from '@tanstack/react-query';
import { GetProductPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';

type ProductDetailsSectionProps = {
  product: ServerProductProps['props']['product'];
};

type Variant = ServerProductProps['props']['product']['variants'][number];

const ProductDetailsRightSection = ({ product }: ProductDetailsSectionProps): React.ReactElement => {
  const router = useRouter();
  const { yotpoAddedToCart } = useYotpoDataLayer();
  const { elevarAddToCartEvent } = useElevarDataLayer();
  const { state } = React.useContext(AppContext);
  const model = product?.tags?.filter((tag) => tag.includes('model_'));

  const [selectedVariant, setSelectedVariant] = React.useState<Variant>();
  const [selectedSSVariantSku, setSelectedSSVariantSku] = React.useState<string>();
  const [client, setClient] = React.useState<boolean>(false);

  const activeVariant = React.useMemo(
    () => selectedVariant || (product?.variants ?? [])[0],
    [selectedVariant, product],
  );

  const [finalSaleModalOpened, { toggle: finalSaleModalOpen, close: finalSaleModalClose }] = useDisclosure(false);
  const [outOfStockModalOpened, { toggle: outOfStockModalOpen, close: outOfStockModalClose }] = useDisclosure(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  const outOfStockVariants =
    product?.variants?.filter((v) => Number(v.quantityAvailable) < 1 || !v.availableForSale) || [];

  const totalEffectivelyAvailableProductStock = product?.variants?.reduce(
    (accumulatedStock, variant) =>
      accumulatedStock + (!variant.availableForSale ? 0 : Number(variant.quantityAvailable) - 0),
    0,
  );

  const isProductEffectivelyOutOfStock: boolean = totalEffectivelyAvailableProductStock === 0;

  const [selectedOutOfStockVariant, setSelectedOutOfStockVariant] = React.useState<Variant>();
  const handleOutOfStockModalOpen = (variant?: Variant) => {
    setSelectedOutOfStockVariant(variant);
    outOfStockModalOpen();
  };

  const [openWearNowPayLaterDialog, setOpenWearNowPayLaterDialog] = React.useState(false);
  const payLaterChannels = useStaticContent('WearNowPayLater.Channels');

  const { data: pricing } = useQuery({
    queryKey: ['productPricing', product.handle],
    enabled: env.MULTICURRENCY_FEATURE,
    queryFn: async () =>
      await GetProductPricing({
        handle: product.handle,
        country: getCustomerCountry(),
      }),
  });

  const price = env.MULTICURRENCY_FEATURE ? pricing?.data?.product?.priceRange.maxVariantPrice : activeVariant?.price;

  const compareAtPrice = env.MULTICURRENCY_FEATURE
    ? pricing?.data?.product?.compareAtPriceRange.maxVariantPrice
    : activeVariant?.compareAtPrice;

  const [isAfterPaySupported, setIsAfterPaySupported] = React.useState(false);

  React.useEffect(() => {
    const customerCountry = getCustomerCountry();
    setIsAfterPaySupported(['AU', 'US', 'NZ'].includes(customerCountry));
  }, []);

  return (
    <>
      <div
        className={cn(
          'p-4 md:sticky md:top-28 md:p-0 md:pb-28',
          'md:h-[calc(100vh-7rem)]',
          'md:overflow-x-hidden md:overflow-y-scroll',
          'md:scrollbar-none',
        )}
      >
        <div>
          {activeVariant && (
            <div>
              <div className="flex items-start justify-between">
                <div className="flex-1 space-y-2 md:space-y-1 md:-mt-px">
                  <ProductBrandFromTags tags={product?.tags} />
                  <h1 className="text-[14px] md:text-base tracking-[0.064px] font-bold uppercase">{product.title}</h1>
                </div>
                <WishlistButton product={product} variant={activeVariant} />
              </div>
              {price && (
                <div className="mt-4 flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
                  <div className="flex items-center space-x-2">
                    {compareAtPrice && +compareAtPrice.amount > 0 && (
                      <div className="text-xs md:text-[14px] text-[#727272] line-through">
                        <Money value={compareAtPrice.amount} currency={compareAtPrice.currencyCode} />
                      </div>
                    )}
                    <div
                      className={cn(
                        'text-xs md:text-[14px]',
                        compareAtPrice && +compareAtPrice.amount > 0.0 && 'text-[#FF0D00]',
                      )}
                    >
                      <Money value={price.amount} currency={price.currencyCode} />
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {product?.tags?.includes('final-sale') && (
                      <Badge
                        color="red.9"
                        bg="#FF0D00"
                        variant="filled"
                        radius="xs"
                        px="0.25rem"
                        sx={{ cursor: 'default' }}
                        onClick={finalSaleModalOpen}
                        className="cursor-pointer"
                      >
                        FINAL SALE
                      </Badge>
                    )}
                  </div>
                </div>
              )}
              {env.PRODUCT_BNPL_FEATURE && isAfterPaySupported && (
                <div className="flex items-center space-x-2 mt-2.5 md:mt-[13.5px]">
                  <div className="text-xs">
                    4 x {env.NEXT_PUBLIC_REGION}${activeVariant.bnplPrice.amount} with
                  </div>
                  <div className="flex items-center space-x-2">
                    {payLaterChannels.includes('afterpay') && <AfterpayIcon height={12} width={63} />}
                    {payLaterChannels.length > 1 && <span className="text-xs">or</span>}
                    {payLaterChannels.includes('klarna') && <KlarnaIcon height={10} width={45} />}
                  </div>
                  <button
                    className="text-xs underline focus:outline-none"
                    onClick={() => setOpenWearNowPayLaterDialog(true)}
                  >
                    More Info
                  </button>
                  <WearNowPayLaterDialog
                    open={openWearNowPayLaterDialog}
                    onClose={() => setOpenWearNowPayLaterDialog(false)}
                  />
                </div>
              )}
            </div>
          )}

          <ReviewSummary className="block mt-4 md:mt-[13.5px]" product={product} />
        </div>
        <Box mt="2.5rem">
          <div className="grid grid-cols-10 gap-1 justify-center mt-2 transition-all">
            {Array.isArray(product.siblings) &&
              product.siblings.map((sibling) => (
                <ColorSwatchImg
                  parentHandle={product.handle ?? ''}
                  key={sibling.handle}
                  handle={sibling.handle}
                  id={sibling.middlewareId}
                  unavailable={!sibling.availableForSale}
                />
              ))}
          </div>
        </Box>
        <div className="mt-8">
          <SizeSelector
            variants={product?.variants as any}
            value={selectedVariant}
            setVariant={setSelectedVariant}
            outOfStockModalOpen={handleOutOfStockModalOpen}
          />

          {outOfStockVariants.length > 0 && (
            <div className="flex justify-center mt-3">
              <Button
                variant="unstyled"
                className="text-xs md:text-xs font-bold tracking-[0.4px] no-underline px-[18px] uppercase"
                onClick={() => handleOutOfStockModalOpen()}
              >
                Where&apos;s my size? Notify Me!
              </Button>
            </div>
          )}

          {isProductEffectivelyOutOfStock ? (
            <OutOfStock />
          ) : (
            <div className="mt-4">
              <AddToCartButton
                label="Add to bag"
                selectedVariant={selectedVariant}
                selectedSSVariantSku={selectedSSVariantSku}
                onSuccess={() => {
                  yotpoAddedToCart(activeVariant.id, 1);
                  elevarAddToCartEvent(
                    [
                      {
                        url: router.asPath,
                        list: router.asPath,
                        position: 1,
                        compare_at_price: activeVariant.compareAtPrice?.amount.toString() ?? '0',
                        brand: product.vendor,
                        category: 'Clothes',
                        id: activeVariant.sku ?? '',
                        name: product.title,
                        price: product.price.amount.toString(),
                        product_id: product.id.split('/').at(-1) ?? '',
                        quantity: '1',
                        variant: activeVariant.sku.split('-').at(-1) ?? '',
                        variant_id: activeVariant.id.split('/').at(-1) ?? '',
                        image: product.images[0].url ?? '',
                      },
                    ],
                    router.asPath,
                  );
                  setSelectedVariant(undefined);
                  setSelectedSSVariantSku(undefined);
                }}
              />
            </div>
          )}

          <div className="mt-[10.5px] text-xs tracking-[0.048px] text-center">
            <StaticContent id="Product.FreeShipping" inline />
          </div>
        </div>

        <Accordion
          className="mt-10"
          classNames={{
            item: 'border-b-black border-b-[0.6px]',
            control: 'px-0 hover:bg-white active:bg-white',
            label: 'text-[14px] font-normal uppercase',
            content: 'text-[14px] tracking-[0.6px] px-0',
          }}
        >
          <Accordion.Item value="details">
            <Accordion.Control>Details</Accordion.Control>
            <Accordion.Panel>
              <Prose
                className="text-[14px] [&_*]:tracking-[0.6px]"
                dangerouslySetInnerHTML={{
                  __html: client ? DOMPurify.sanitize(product.descriptionHtml) : '',
                }}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="delivery-and-returns">
            <Accordion.Control>Delivery and Returns</Accordion.Control>
            <Accordion.Panel lh="26px" lts="0.03em">
              <StaticContent id="Product.DeliveryAndReturns" />
            </Accordion.Panel>
          </Accordion.Item>
          {product.productType !== 'Accessories' && (
            <Accordion.Item value="sizing">
              <Accordion.Control>Sizing and Fit</Accordion.Control>
              <Accordion.Panel px="0.5rem">
                <Stack spacing="2rem">
                  <SizesTable productType={product.productType as any} />
                  {modelData.filter((modelInfo) => modelInfo.title === (Array.isArray(model) ? model[0] : '')).length >
                    0 && (
                    <ModelCard
                      title
                      model={
                        modelData.filter((modelInfo) => modelInfo.title === (Array.isArray(model) ? model[0] : ''))[0]
                      }
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>

        <CartUpsellCarousel
          beaconPlacement="product-page"
          classNames={{
            root: 'bg-[#FAF1F5] -mx-4 md:mx-0 mt-4',
            viewport: 'p-4 md:px-0 md:mx-10',
            controls: 'mx-2',
          }}
        />
      </div>
      <FinalSaleModal opened={finalSaleModalOpened} onClose={finalSaleModalClose} />
      <OutOfStockModal
        opened={outOfStockModalOpened}
        onClose={outOfStockModalClose}
        productTitle={product.title as string}
        productVariants={outOfStockVariants as any}
        initialVariantId={selectedOutOfStockVariant?.id}
      />
      <StickyAddToCartPanel
        product={product as any}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        ssVariantSkus={state.ssViewedProduct.variantSkus}
        selectedSSVariantSku={selectedSSVariantSku}
        setSelectedSSVariantSku={setSelectedSSVariantSku}
        outOfStockVariants={outOfStockVariants as any}
        onOutOfStockVariantsClick={outOfStockModalOpen}
        isProductEffectivelyOutOfStock={isProductEffectivelyOutOfStock}
        outOfStockModalOpen={outOfStockModalOpen}
      />
    </>
  );
};

export default ProductDetailsRightSection;

const ColorSwatchImg = ({
  parentHandle,
  handle,
  id,
  unavailable,
}: {
  parentHandle: string;
  handle: string;
  id: string;
  unavailable?: boolean;
}): React.ReactElement => {
  return (
    <div
      key={id}
      className={cn(
        'color-swatch ml-1 h-7 w-7 p-[2px] relative',
        handle === parentHandle && 'ring-1 ring-offset-1 rounded-full ring-black',
      )}
    >
      <Link href={`/products/${handle}`} className="relative flex items-center justify-center">
        <picture>
          <img
            className={cn('rounded-full m-auto', unavailable && 'opacity-30')}
            alt={handle}
            src={`https://assets.hellomolly.com/samples/${id}.webp`}
            onError={(e) => {
              (e.target as HTMLElement).closest('.color-swatch')?.classList.add('hidden');
            }}
          />
        </picture>
        {unavailable && (
          <svg
            className="w-4 h-4 absolute pointer-events-none"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.071 15.3865L0.928909 1.24434" stroke="#9E9E9E" strokeWidth="1.2" strokeLinecap="round" />
          </svg>
        )}
      </Link>
    </div>
  );
};
