import { keepPreviousData, useQuery } from '@tanstack/react-query';
import getProductReviews, { ProductReview } from '@ui/axios/yotpo/getProductReviews';
import { CloseIcon, LeftChevronIcon, Pagination, RightChevronIcon } from '@ui/components/core';
import { Button, ProductReviewDialog } from '@ui/components/shared';
import React from 'react';
import { Accordion, ActionIcon, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ProductReviewCard from './ProductReviewCard';
import ProductReviewForm from './ProductReviewForm';
import env from '@ui/env';
import axios from 'axios';
import cn from '@ui/utils/cn';
import { ServerProductProps } from '@ui/nextServer';

type ProductDetailsReviewSectionProps = {
  product: ServerProductProps['props']['product'];
};

const ProductDetailsReviewsSection = ({ product }: ProductDetailsReviewSectionProps) => {
  const [page, setPage] = React.useState(1);

  const { data, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['productDetailsPageReviews', page, product.handle],
    queryFn: async () => {
      const productId = env.PRODUCT_REVIEWS_REROUTE_TO_AU
        ? (await axios.get(`https://content.hellomolly.com.au/products/${product.handle}.json`)).data.product.id
        : product.id;

      return getProductReviews({
        productId,
        perPage: 3,
        page,
      }).catch(() => {
        return null;
      });
    },
  });

  React.useMemo(() => {
    if (!product.handle) return;
    setPage(1);
  }, [product.handle]);

  const pagination = data?.data.response.pagination || { total: 0 };
  const bottomline = data?.data.response.bottomline || {
    average_score: 0,
    total_review: 0,
  };
  const reviews = data?.data.response.reviews || [];

  const [createFormOpened, { open: openCreateForm, close: closeCreateForm }] = useDisclosure();
  const [confirmationOpened, { open: openConfirmation, close: closeConfirmation }] = useDisclosure();

  const OpenFormButton = () => (
    <Button
      data-review-form-open
      variant="outline"
      className="w-full text-[14px] font-normal tracking-[0.18px] h-10 md:w-auto"
      disabled={!env.PRODUCT_REVIEWS_CREATE_FEATURE}
      onClick={() => {
        closeConfirmation();
        openCreateForm();
      }}
    >
      Write a Review
    </Button>
  );

  const CloseFormButton = () => (
    <ActionIcon onClick={closeCreateForm}>
      <CloseIcon width={20} height={20} />
    </ActionIcon>
  );

  const CloseConfirmationButton = () => (
    <ActionIcon onClick={closeConfirmation}>
      <CloseIcon width={20} height={20} />
    </ActionIcon>
  );

  const [selectedReview, setSelectedReview] = React.useState<ProductReview>();
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <div id="reviews" className="scroll-mt-12">
      <Accordion
        defaultValue="reviews"
        classNames={{
          item: 'border-b-0',
          control: 'px-0 hover:bg-white active:bg-white',
          label: 'text-[14px] font-bold p-0',
          content: 'p-0',
        }}
      >
        <Accordion.Item value="reviews">
          <Accordion.Control>REVIEWS</Accordion.Control>
          <Accordion.Panel>
            {createFormOpened && (
              <div className="mt-4 mb-20">
                {!(bottomline.total_review === 0) && (
                  <div className="flex items-end space-x-2 md:h-10">
                    <div className="text-[24px] leading-none font-normal">{bottomline.average_score.toFixed(1)}</div>
                    <div className="text-xs text-black/80 font-normal">
                      {bottomline.total_review != 1 ? `${bottomline.total_review} reviews` : '1 review'}
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-between mt-4 mb-6">
                  <div className="text-[14px] tracking-[0.056px] font-bold">WRITE A REVIEW</div>
                  <CloseFormButton />
                </div>

                <ProductReviewForm
                  product={{
                    id: product.id ?? '',
                    title: product.title ?? '',
                    handle: product.handle ?? '',
                    url: `${env.NEXT_PUBLIC_BASE_URL}/products/${product.handle}/`,
                  }}
                  onSuccess={() => {
                    openConfirmation();
                    closeCreateForm();
                  }}
                />
              </div>
            )}

            {confirmationOpened && (
              <div className="my-6">
                <div className="border border-black flex flex-col items-center justify-center text-center p-10 md:p-12 relative">
                  <div className="text-[13px] md:text-base font-normal tracking-[0.064px]">
                    THANK YOU FOR YOUR REVIEW
                  </div>
                  <div className="text-[13px] md:text-base text-black/70 mt-4">
                    Please note all reviews are screened for profanity and offensive material before being published.
                  </div>
                  <div className="absolute right-4 top-4">
                    <CloseConfirmationButton />
                  </div>
                </div>
              </div>
            )}

            {!createFormOpened && (
              <div className="flex flex-col mt-4 md:flex-row">
                <div className="flex items-end space-x-2">
                  <div className="text-[24px] leading-none font-normal">{bottomline.average_score.toFixed(1)}</div>
                  <div className="text-xs text-black/80 font-normal">
                    {bottomline.total_review != 1 ? `${bottomline.total_review} reviews` : '1 review'}
                  </div>
                </div>
                <div className="mt-4 md:ml-auto md:mt-0">
                  <OpenFormButton />
                </div>
              </div>
            )}

            {reviews.length > 0 && (
              <div className="relative isolate">
                <LoadingOverlay
                  className="pt-12 items-start md:pt-0 md:items-center"
                  visible={isLoading}
                  overlayBlur={1}
                />

                <div
                  className={cn(
                    'grid grid-cols-1 md:grid-cols-3 md:gap-7',
                    'divide-y-[0.6px] divide-black md:divide-y-0',
                    '[&>*]:py-8 md:[&>*]:py-0 md:mt-6',
                  )}
                >
                  {reviews.map((review) => (
                    <ProductReviewCard
                      key={review.id}
                      review={review}
                      onImageClick={() => {
                        setSelectedReview(review);
                        setOpenDialog(true);
                      }}
                    />
                  ))}
                </div>

                <ProductReviewDialog review={selectedReview} open={openDialog} onClose={() => setOpenDialog(false)} />

                {env.PRODUCT_REVIEWS_PAGINATION_FEATURE && (
                  <Pagination
                    value={page}
                    onChange={(newPage) => {
                      setPage(newPage);

                      const isDesktop = window.matchMedia('(min-width: 62em)').matches;

                      if (!isDesktop) {
                        const reviewsContainer = document.getElementById('reviews');
                        reviewsContainer?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                    total={Math.ceil(pagination.total / 3)}
                    className="gap-0 justify-center md:justify-end mt-6"
                    previousIcon={() => <LeftChevronIcon width={16} height={16} />}
                    nextIcon={() => <RightChevronIcon width={16} height={16} />}
                    classNames={{
                      dots: 'text-black/20',
                      control: cn(
                        'text-[13px] rounded-sm md:text-[11px] border-none min-w-7 h-7',
                        'data-[active]:!bg-white data-[active]:text-[#ECB8CF]',
                        'data-[active]:hover:text-black',
                      ),
                    }}
                  />
                )}
              </div>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ProductDetailsReviewsSection;
