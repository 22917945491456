import { CloseIcon } from '@ui/components/core';
import { AddedToCart, Button, SizeSelector, Text, WishlistButton } from '@ui/components/shared';
import OutOfStock from '@ui/components/shared/labels/OutOfStock/OutOfStock';
import env from '@ui/env';
import { CartLine } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { ActionIcon, Drawer, Group, LoadingOverlay, Portal, Transition } from '@mantine/core';
import React from 'react';
import AddToCartButton from './AddToCartButton';
import { useDisclosure } from '@mantine/hooks';
import Link from 'next/link';
import useAddToCart from '@ui/hooks/useAddToCart';
import { AppContext } from '@ui/context/context';
import { ServerProductProps } from '@ui/nextServer';

type StickyAddToCartPanelProps = {
  product: ServerProductProps['props']['product'];
  selectedVariant: ServerProductProps['props']['product']['variants'][number] | undefined;
  setSelectedVariant: (_value: ServerProductProps['props']['product']['variants'][number] | undefined) => void;
  ssVariantSkus: Array<string>;
  selectedSSVariantSku: string | undefined;
  setSelectedSSVariantSku: (_value: string | undefined) => void;
  outOfStockVariants?: Array<ServerProductProps['props']['product']['variants'][number]>;
  onOutOfStockVariantsClick?: () => void;
  isProductEffectivelyOutOfStock: boolean;
  outOfStockModalOpen: () => void;
};

const StickyAddToCartPanel = ({
  product,
  selectedVariant,
  setSelectedVariant,
  ssVariantSkus,
  selectedSSVariantSku,
  setSelectedSSVariantSku,
  outOfStockVariants,
  onOutOfStockVariantsClick,
  isProductEffectivelyOutOfStock,
  outOfStockModalOpen,
}: StickyAddToCartPanelProps): React.ReactElement => {
  const { state } = React.useContext(AppContext);

  const activeVariant = React.useMemo(() => {
    return selectedVariant || (product.variants && product?.variants[0]);
  }, [selectedVariant, product]);

  const panelRef = React.useRef<HTMLDivElement>(null);

  const [sizeModalOpened, { open: openSizeModal, close: closeSizeModal }] = useDisclosure();
  const [recentlyAdded, { open: openRecentlyAdded, close: closeRecentlyAdded }] = useDisclosure();

  const addBottomPaddingToBody = React.useCallback(() => {
    document.body.style.paddingBottom = `${panelRef.current?.offsetHeight}px`;
  }, []);

  const removeBottomPaddingFromBody = React.useCallback(() => {
    document.body.style.removeProperty('padding-bottom');
  }, []);

  React.useEffect(() => {
    return removeBottomPaddingFromBody;
  }, [removeBottomPaddingFromBody]);

  const { addToCart, isLoading } = useAddToCart({
    onSuccess: () => {
      setTimeout(openRecentlyAdded, 150);
      setSelectedVariant(undefined);
      setSelectedSSVariantSku(undefined);
    },
  });

  React.useEffect(() => {
    if (!recentlyAdded) return;
    const timeoutId = setTimeout(closeRecentlyAdded, 2000);
    return () => clearTimeout(timeoutId);
  }, [recentlyAdded, closeRecentlyAdded]);

  const handleOutOfStock = () => {
    closeSizeModal();
    outOfStockModalOpen();
  };

  return (
    <>
      <Portal>
        <Transition
          mounted={false}
          duration={300}
          timingFunction="cubic-bezier(0, 0, 0.2, 1)"
          transition="slide-up"
          onEntered={addBottomPaddingToBody}
          onExited={removeBottomPaddingFromBody}
        >
          {(containerStyles) => (
            <div className="pointer-events-none fixed w-full bottom-0 z-10 space-y-4" style={containerStyles}>
              {/* <ScrollToTopButton className="pointer-events-auto ml-auto" position="static" mr="1.5rem" /> */}
              <div ref={panelRef} className="w-full pointer-events-auto">
                <div className="hidden md:flex items-center h-[100px] px-8 bg-white shadow-[5px_0px_6px_0px_#00000040]">
                  <div className="w-1/3">
                    <Text tt="uppercase" fw={700} lts="0.04em">
                      {product.title}
                    </Text>
                  </div>
                  <div className="w-1/3">
                    <SizeSelector
                      variants={product.variants}
                      ssVariantSkus={ssVariantSkus}
                      value={selectedVariant}
                      setVariant={setSelectedVariant}
                      setSSVariantSku={setSelectedSSVariantSku}
                      outOfStockModalOpen={handleOutOfStock}
                    />
                  </div>
                  <div className="w-1/3 flex items-center justify-end space-x-4">
                    {isProductEffectivelyOutOfStock ? (
                      <OutOfStock />
                    ) : (
                      <div className="w-[332px]">
                        <AddToCartButton
                          selectedVariant={selectedVariant}
                          selectedSSVariantSku={selectedSSVariantSku}
                          onSuccess={() => {
                            setSelectedVariant(undefined);
                            setSelectedSSVariantSku(undefined);
                          }}
                          label={
                            <Group spacing="0.5rem">
                              <span>Add to Bag</span>
                              <span>&mdash;</span>
                              <span>
                                {activeVariant ? `${env.NEXT_PUBLIC_REGION}$${activeVariant.price.amount}` : ''}
                              </span>
                            </Group>
                          }
                        />
                      </div>
                    )}

                    <WishlistButton product={product as any} variant={activeVariant} />
                  </div>
                </div>
                <div className="md:hidden">
                  <div className="px-4 pt-2 pb-6 bg-gradient-to-b from-transparent via-white to-white">
                    {!isProductEffectivelyOutOfStock ? (
                      <div className="relative overflow-hidden">
                        <Button
                          className="w-full"
                          loading={isLoading}
                          onClick={() => {
                            setSelectedVariant(undefined);
                            setSelectedSSVariantSku(undefined);
                            openSizeModal();
                          }}
                        >
                          ADD TO BAG
                        </Button>
                        <AddedToCart show={recentlyAdded} fill />
                      </div>
                    ) : (
                      <Button className="w-full" variant="secondary" onClick={onOutOfStockVariantsClick}>
                        {`Where's my size? Notify Me!`}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </Portal>

      <Drawer
        position="bottom"
        transitionProps={{
          duration: 150,
          timingFunction: 'ease-in-out',
        }}
        withOverlay
        withCloseButton={false}
        opened={sizeModalOpened}
        onClose={closeSizeModal}
        classNames={{
          content: 'rounded-t-[4px] rounded-b-0 h-auto',
          overlay: 'bg-black/20',
          body: 'p-4 pb-6',
        }}
      >
        <LoadingOverlay visible={isLoading} overlayBlur={1} />
        <div className="flex items-start justify-between pb-2 border-b border-b-[#BDBDBD]">
          <div className="text-xs font-bold">SELECT SIZE</div>
          <ActionIcon onClick={closeSizeModal} size="xs">
            <CloseIcon width={16} height={16} />
          </ActionIcon>
        </div>
        <div className="mt-[18px] space-y-3">
          <div className="flex items-center justify-between">
            <button
              className="text-[10px] font-normal underline underline-offset-4 disabled:opacity-0 focus:outline-none"
              disabled={!outOfStockVariants?.length}
              onClick={handleOutOfStock}
            >
              {`WHERE'S MY SIZE? NOTIFY ME!`}
            </button>
            <Link
              className="text-[10px] font-normal underline underline-offset-4"
              href="/pages/size-guide"
              target="_blank"
              rel="noreferrer noopener"
            >
              Size Guide
            </Link>
          </div>
          <SizeSelector
            variants={product.variants}
            ssVariantSkus={ssVariantSkus}
            value={selectedVariant}
            setVariant={setSelectedVariant}
            setSSVariantSku={setSelectedSSVariantSku}
            outOfStockModalOpen={handleOutOfStock}
            onVariantClick={(variant, variantSku) => {
              addToCart({
                productVariantId: variant.id,
                intellisuggestData: state.ssViewedProduct.intellisuggestData,
                intellisuggestSignature: state.ssViewedProduct.intellisuggestSignature,
              });

              closeSizeModal();
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

StickyAddToCartPanel.defaultProps = {
  outOfStockVariants: undefined,
  onOutOfStockVariantsClick: undefined,
};

export default StickyAddToCartPanel;
