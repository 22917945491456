import React from 'react';
import { Box, Card, Group, List, NextImage, Stack } from '../../../core';
import { Text } from '../../typography';
import { ShopifyImageLoader } from '../../../../utils/ShopifyImageLoader';

type ModelCardProps = {
  title?: boolean;
  model: any;
};

const ModelCard = ({ title, model }: ModelCardProps): React.ReactElement => (
  <Stack>
    {title && (
      <Text fw="700" fz="xs" tt="uppercase">
        Model
      </Text>
    )}
    <Card radius="2px" sx={{ border: '1px solid #000' }}>
      <Group spacing="1rem" noWrap>
        <Box w="30%">
          <NextImage
            loader={ShopifyImageLoader}
            src={model.model_image}
            alt=""
            width={500}
            height={750}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box w="70%">
          <Text fw={700} tt="capitalize">{`${model.model_tag.replace(/model_/, '')}`}</Text>
          <List mt="0.5rem" styles={{ item: { fontSize: '13px', letterSpacing: '0.04em' } }}>
            <List.Item>{`Height : ${model.model_height}`}</List.Item>
            <List.Item>{`Waist : ${model.model_waist}`}</List.Item>
            <List.Item>{`Dress : ${model.model_dress_size}`}</List.Item>
            <List.Item>{`Hips : ${model.model_hips}`}</List.Item>
            <List.Item>{`Bust : ${model.model_bust}`}</List.Item>
            <List.Item>{`Shoe : ${model.model_shoe_size}`}</List.Item>
          </List>
        </Box>
      </Group>
    </Card>
  </Stack>
);

ModelCard.defaultProps = {
  title: false,
};

export default ModelCard;
